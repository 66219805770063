import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { countries } from 'shared_data/countries';
import { Brand } from 'shared_models/brand';
import { ProductType } from 'shared_models/product-type';
import { PriceUnit } from 'shared_models/device';
import { RequiredAddressFields } from 'shared_models/stripe';
import { PricingModel } from '../../components/device-setup/setup-models';
import { DashboardUser } from 'shared_models/dashboard-user';
import 'firebase/compat/auth'; // If using Firebase auth
import { TranslateService } from '@ngx-translate/core';
import { SubCustomerPermission } from 'shared_models/sub-customer';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { ControlledUser } from 'shared_models/controlled-user';
import { CurrencyConversionRates } from 'shared_models/curreny-conversion-rates';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { SnapshotAction } from '@angular/fire/compat/database/interfaces';
import moment from 'moment';
import { Clipboard } from '@angular/cdk/clipboard';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { DescriptionPart } from 'shared_models/misc';
import { take } from 'rxjs/operators';
import { requiredAddressProperties } from './reqAddressProps';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
interface ToastMessages {
    message: string;
    bold?: boolean;
    numberOfBreakLines?: number;
}

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    constructor(
        private authService?: AuthService,
        private localStorageService?: LocalStorageService,
        private toast?: ToastrService,
        private translate?: TranslateService,
        private clipboard?: Clipboard,
        private db?: AngularFireDatabase
    ) {}

    async sleep(ms: number) {
        return new Promise(res => {
            setTimeout(res, ms);
        });
    }

    buildMinOrMsValuesArray(numberOfItems: number, multiplier?: number): number[] {
        const arr = [];
        for (let index = 1; index <= numberOfItems; index++) {
            const value = multiplier * index;
            arr.push(value);
        }

        return arr;
    }

    localizeNumberWithCurrency(sourceValue: number | string, maximumFractionDigits?: number, minimumFractionDigits?: number, currency?: string): string {
        const user: DashboardUser = this.getUser();

        return Number(sourceValue).toLocaleString(
            window.navigator.language.substring(0, 2), // locale

            {
                currency: currency ? currency : user.settings && user.settings.currency ? user.settings.currency : 'eur',
                currencyDisplay: 'code',
                style: 'currency',
                currencySign: 'accounting',
                maximumFractionDigits: isNaN(maximumFractionDigits) ? 2 : maximumFractionDigits,
                minimumFractionDigits: isNaN(minimumFractionDigits) ? 2 : minimumFractionDigits
            }
        );
    }

    localizeNumberWithCurrencyAndConversion(sourceValue: number, inputCurrency: string, outputCurrency: string, conversionRates: Record<string, number>, maximumFractionDigits?: number, minimumFractionDigits?: number): string {
        // conversionRate is the rate that the sourceValue is in compared to EUR
        // outputCurrency is the currency that should be applied (converted into)
        console.log(conversionRates);

        const amountInDefault: number = (sourceValue / conversionRates[inputCurrency.toUpperCase()]) * conversionRates[outputCurrency.toUpperCase()];

        return amountInDefault.toLocaleString(
            window.navigator.language.substring(0, 2), // locale
            {
                currency: outputCurrency,
                currencyDisplay: 'code',
                style: 'currency',
                maximumFractionDigits: isNaN(maximumFractionDigits) ? 2 : maximumFractionDigits,
                minimumFractionDigits: isNaN(minimumFractionDigits) ? 2 : minimumFractionDigits
            }
        );
    }

    localizeNumberWithCurrencyAndConversionForRecord(currencyAmounts: Record<string, number>, outputCurrency: string, conversionRates: Record<string, number>, maximumFractionDigits?: number, minimumFractionDigits?: number): string {
        let totalValue = 0;

        if (!currencyAmounts) {
            return totalValue.toLocaleString(
                window.navigator.language.substring(0, 2), // locale
                {
                    currency: outputCurrency,
                    currencyDisplay: 'code',
                    style: 'currency',
                    maximumFractionDigits: isNaN(maximumFractionDigits) ? 2 : maximumFractionDigits,
                    minimumFractionDigits: isNaN(minimumFractionDigits) ? 2 : minimumFractionDigits
                }
            );
        }

        Object.keys(currencyAmounts).forEach(key => {
            if (key.toUpperCase() === outputCurrency.toUpperCase()) {
                totalValue += currencyAmounts[key] / 100;
            } else {
                totalValue += (currencyAmounts[key] / 100 / conversionRates[key.toUpperCase()]) * conversionRates[outputCurrency.toUpperCase()];
            }
        });

        return totalValue.toLocaleString(
            window.navigator.language.substring(0, 2), // locale
            {
                currency: outputCurrency,
                currencyDisplay: 'code',
                style: 'currency',
                maximumFractionDigits: isNaN(maximumFractionDigits) ? 2 : maximumFractionDigits,
                minimumFractionDigits: isNaN(minimumFractionDigits) ? 2 : minimumFractionDigits
            }
        );
    }

    async getCurrencyConversionRates(): Promise<CurrencyConversionRates> {
        const dataRef = this.db.object('conversion_rates');
        const data$: Observable<any> = dataRef.valueChanges();

        // Using the take(1) operator to complete the observable after receiving the first value
        // Alternatively, you can use the first() operator
        const data = await lastValueFrom(data$.pipe(take(1)));

        return data as CurrencyConversionRates;
    }

    localizeNumber(sourceValue: number | string, maximumFractionDigits?: number, minimumFractionDigits?: number): string {
        return Number(sourceValue).toLocaleString(
            window.navigator.language.substring(0, 2), // locale
            {
                maximumFractionDigits: isNaN(maximumFractionDigits) ? 2 : maximumFractionDigits,
                minimumFractionDigits: isNaN(minimumFractionDigits) ? 2 : minimumFractionDigits
            }
        );
    }

    getCurrentDashboardVersion(): AngularFireObject<any> {
        return this.db.object(`dashboard_version`);
    }

    getMaintenanceState(): AngularFireObject<any> {
        return this.db.object(`dashboard_maintenance`);
    }

    createPushKey(): string {
        return this.db.createPushId();
    }

    brandEnumToArray(): string[] {
        // this function takes the enum 'Brand' and coverts the keys into human readable labels, so if new brands are added to the model will automatically be added to the select that uses it.
        const brandsArr: string[] = Object.keys(Brand); // enum keys into array
        for (const [i, brand] of brandsArr.entries()) {
            if (!brand.match(/^[A-Z0-9\s]*$/))
                // if all letters is uppercase, skip it
                brandsArr[i] = brand.replace(/([A-Z])/g, ' $1').trim(); // Insert space between uppercase letters, and trim to remove space from begining of word
        }
        return brandsArr;
    }

    getPriceUnit(productType: string, pricingModel: string, currency?: string, priceUnit?: PriceUnit, incrementNumber?: number): string {
        if (!currency)
            // on init
            return '';

        if (productType === ProductType.Solarium) return `${currency.toUpperCase()}/min`;

        if (priceUnit === PriceUnit.Increment && incrementNumber) {
            return `${currency.toUpperCase()}/${incrementNumber} min`;
        } else if (priceUnit === PriceUnit.Increment) {
            return `${currency.toUpperCase()}/X min`;
        }

        if (priceUnit === PriceUnit.Hour || priceUnit === PriceUnit.Minute) {
            return `${currency.toUpperCase()}${priceUnit === PriceUnit.Hour ? priceUnit.substring(0, 2) : priceUnit.substring(0, 4)}`;
        }

        if (pricingModel === PricingModel.Fixed || priceUnit === PriceUnit.Fixed || priceUnit === PriceUnit.None) return `${currency.toUpperCase()}`;

        return `${currency.toUpperCase()}/h`;
    }

    protocolVersionValidator(protocolVersion: string): { version: string; legacy: boolean } {
        if (!protocolVersion) {
            return { version: '1.0.0', legacy: true };
        } else if (parseInt(protocolVersion.split('.')[0]) === 1 && parseInt(protocolVersion.split('.')[1]) <= 5 && parseInt(protocolVersion.split('.')[2]) === 0) {
            return { version: protocolVersion, legacy: true };
        } else {
            return { version: protocolVersion, legacy: false };
        }
    }
    unguardedUrl(uri: string): boolean {
        if (uri.includes('sign-in') || uri.includes('sign-up') || uri.includes('set-password') || uri.split('/')[1] === 'team-member') return true;
        return false;
    }

    async setActiveMenuHighlighter(event: any) {
        if (this.unguardedUrl(event.url)) {
            return;
        }

        for (let index = 0; index < 6; index++) {
            if (!(document.getElementsByClassName('navbar-nav') && document.getElementsByClassName('navbar-nav').length > 0)) {
                await this.sleep(index * 100); // sleeping for 100ms, 200ms, 300ms, 400ms, and 500ms, inbetween it will check if the HTML classes are present. When they are present, the sleep loop will break.
            } else {
                break;
            }
        }

        const menuItems = Array.from(document.getElementsByClassName('navbar-nav')[0].children); // Array< Record< id: string, .... >, ... >
        const currentPage: string = event.url === '/' ? 'overview' : event.url.split('/')[1];
        const settingsTabOptions = ['account', 'billing', 'settlements', 'developer', 'team-members'];
        const adminTabOptions = ['support'];
        // List contains all submenus, is needed to remove the 'active' property from submenus
        const submenuIdList = ['account', 'billing', 'settlements', 'support', 'developer', 'customers', 'contracts', 'actions', 'devices', 'team-members'];
        this.removeActiveClassFromSubmenus(submenuIdList);

        const isSettingsMenuOption: boolean = settingsTabOptions.includes(currentPage);
        const ref: string = isSettingsMenuOption ? 'settings' : adminTabOptions.includes(currentPage) ? 'admin' : currentPage;
        for (const item of menuItems) {
            if (item && item.id) {
                if (ref === item.id) {
                    document.getElementById(item.id).setAttribute('class', 'nav-item active');
                    if (ref === 'settings' && document.getElementById(currentPage)) {
                        document.getElementById(item.id).setAttribute('class', 'nav-item dropdown active');
                        document.getElementById(currentPage).setAttribute('class', 'dropdown-item my-pointer active-sub-button');
                    } else if (ref === 'operator') {
                        const subpageId = event.url.split('/')[2].split('?')[0]; //Takes 2nd part of url (before '?'), e.g. 'contracts' and sets submenu button to active as well
                        document.getElementById(subpageId).setAttribute('class', 'dropdown-item my-pointer active-sub-button');
                    } else if (ref === 'admin' && document.getElementById(currentPage)) {
                        document.getElementById(item.id).setAttribute('class', 'nav-item dropdown active');
                        document.getElementById(currentPage).setAttribute('class', 'dropdown-item my-pointer active-sub-button');
                    }
                } else {
                    document.getElementById(item.id).setAttribute('class', 'nav-item');
                }
            }
        }
    }
    removeActiveClassFromSubmenus(submenuIdList) {
        for (const id of submenuIdList) {
            try {
                const ele = document.getElementById(id);
                ele.setAttribute('class', 'dropdown-item my-pointer');
            } catch (error) {}
        }
    }

    readSubCustomerPermission(uid: string): AngularFireObject<SubCustomerPermission> {
        return this.db.object(`customers/${uid}/permissions`);
    }

    validateStripeLocationAddressFields(countryCode?: string): RequiredAddressFields {
        // two characters country code
        const requiredFields: RequiredAddressFields = {
            country: true,
            line1: true,
            line2: false, // default is false
            state: false, // default is false
            city: true,
            postal_code: true
        };

        const countryRequiredFields = requiredAddressProperties[countries[countryCode].country];

        if (countryRequiredFields) {
            return countryRequiredFields;
        }

        return requiredFields;
    }

    getCountryTranslated = (country: string, isCountryCode?: boolean): string => {
        isCountryCode ? (country = countries[country].country) : null;
        return this.translate.instant('country.' + country.toLowerCase().replace(/ /g, '_'));
    };

    /**
     * Creates a more detailed Toast:
     * Array<ToastMessages>{ message: 'My message', bold?: True | False, numberOfBreakLines: number }
     */

    htmlToast = (messages: ToastMessages[], type: 'Error' | 'Success' | 'Warning' | 'Info', options?: Partial<IndividualConfig>, title?: string): ActiveToast<any> => {
        const handleMessages = (messages: any[]) => {
            let html = ``;
            for (const msg of messages) {
                if (msg.bold) {
                    html += `<span class="toast-span-bold">${msg.message}</span>`;
                } else {
                    html += `<span>${msg.message}</span>`;
                }
                if (msg.numberOfBreakLines > 0) {
                    for (let i = 0; i < msg.numberOfBreakLines; i++) {
                        html += `</br>`;
                    }
                }
            }
            return html;
        };
        return this.toast[type.toLowerCase()](handleMessages(messages), title ? title : type === 'Warning' ? this.translate.instant('misc.attention') : this.translate.instant(`misc.${type.toLowerCase()}`), options ? { ...options, ...{ enableHtml: true } } : { enableHtml: true });
    };

    defaultHtmlToast = (boldString: string, message: string, type: 'Error' | 'Success' | 'Warning' | 'Info', options?: Partial<IndividualConfig>, title?: string): ActiveToast<any> => {
        if (boldString === '') {
            return this.htmlToast(
                [
                    {
                        message: message
                    }
                ],
                type,
                options ? { ...options, ...{ enableHtml: true } } : { enableHtml: true }
            );
        } else {
            return this.htmlToast(
                [
                    {
                        message: boldString,
                        bold: true,
                        numberOfBreakLines: 1
                    },
                    {
                        message: message
                    }
                ],
                type,
                options ? { ...options, ...{ enableHtml: true } } : { enableHtml: true }
            );
        }
    };

    getUser(skipSignOut?: boolean): DashboardUser {
        if (!skipSignOut && !this.compareUserToLocalStorage('user')) {
            // <---- Culprit

            if (!window.location.pathname.includes('/sign-in')) {
                this.localStorageService.setCallbackUrl(window.location.pathname); // trying to access a guarded page, will remember for redirect after sign in.
                this.authService.signOut(false);
            }
        }
        return JSON.parse(localStorage.getItem('controlled_user')) ? JSON.parse(localStorage.getItem('controlled_user')) : JSON.parse(localStorage.getItem('user'));
    }

    /**
     * @description Should be updated according to the user interface
     * @interface DashboardUser
     * @param itemName
     * @returns boolean
     */
    compareUserToLocalStorage(itemName: string): boolean {
        const localItem: any = localStorage.getItem(itemName) ? JSON.parse(localStorage.getItem(itemName)) : null;
        if (localItem === null) {
            return false;
        }
        function instanceOfUser(object: any): object is DashboardUser | ControlledUser {
            return 'uid' in object && 'email' in object && 'settings' in object;
        }
        return instanceOfUser(localItem);
    }

    isUserControlled(): boolean {
        return localStorage.getItem('user') && localStorage.getItem('controlled_user') ? true : false;
    }

    formatNumberWithThousandSeparators(num: number): string {
        const locale = navigator.language;
        console.log('locale', locale);
        return new Intl.NumberFormat(locale).format(num);
    }

    getLocalizedDateAndTime(unixTimestamp: number): string {
        const date = this.getLocalizedDate(unixTimestamp);
        const time = this.getLocalizedTime(unixTimestamp);
        return date + ' - ' + time;
    }

    getLocalizedDate(unixTimestamp: number): string {
        const format = moment.localeData(navigator.language).longDateFormat('L');
        return moment(unixTimestamp, 'X').format(format);
    }

    getLocalizedTime(unixTimestamp: number): string {
        const format = moment.localeData(navigator.language).longDateFormat('LT');
        return moment(unixTimestamp, 'X').format(format);
    }

    getDeviceUnitPriceTranslated(priceUnit: PriceUnit, pulse_increment?: number) {
        switch (priceUnit) {
            case PriceUnit.Fixed:
                return '';
            case PriceUnit.None:
                return '';
            case PriceUnit.Hour:
                return this.translate.instant('misc./hour_short');
            case PriceUnit.Minute:
                return this.translate.instant('misc./minute_short');
            case PriceUnit.Increment:
                return `/${pulse_increment ? pulse_increment : 0} ${this.translate.instant('misc./minute_short').split('/')[1]}`;
        }
    }

    formatCurrency(value: number | null, currency: string) {
        if (isNaN(value) || value === null) {
            return '-';
        }
        return this.localizeNumberWithCurrency(value ? value / 100 : 0, 2, 2, currency);
    }

    makeFirstCharUppercase(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    createdLabelForLists(created: number): string {
        if (moment(String(created), 'X').year() === moment().year()) {
            return moment(String(created), 'X').locale(this.translate.currentLang).format('D MMM'); // short formart for current year
        }
        return moment(String(created), 'X').locale(this.translate.currentLang).format('D MMM YYYY'); // else for previous years, longer format
    }

    async copySomeText(textToCopy: string, idForNotifyCopiedAction: string) {
        const elm: HTMLElement = document.getElementById(idForNotifyCopiedAction);
        this.clipboard.copy(textToCopy);
        if (elm.innerHTML.replace(/\s/g, '') === textToCopy) {
            elm.innerHTML = this.translate.instant('transactions.copied');
            elm.className = 'copied';
            await this.sleep(1.75 * 1000);
            elm.className = 'clip';
            elm.innerHTML = textToCopy;
        }
    }

    formatterPhoneNumber(number: string, region?: string): string {
        try {
            const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
            const phoneNumber = phoneUtil.parse(number, region ? region : 'EU');
            const formattedNumber = phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
            const splittedArr = formattedNumber.split(' ');
            const countryCode = splittedArr.shift(); // Removing and saving the country code
            const phoneNumberPart = splittedArr.join(''); // Rejoin the rest of the phone number and removing spaces between numbers
            return `${countryCode} ${phoneNumberPart}`;
        } catch (error) {
            return number;
        }
    }

    normalizePhoneNumber(number: string, region?: string): string {
        try {
            const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
            const phoneNumber = phoneUtil.parse(number, region ? region : 'EU');
            const formattedNumber = phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
            const splittedArr = formattedNumber.split(' ');
            const code = splittedArr[0];

            let str = '';
            for (let i = 1; i < splittedArr.length; i++) {
                str += splittedArr[i];
            }

            return `${code} ${str}`;
        } catch (error) {
            return number;
        }
    }

    isStrongPassword(password: string): {
        is_strong: boolean;
        tests: {
            passed_min_length: boolean;
            passed_upper_case: boolean;
            passed_lower_case: boolean;
            passed_number: boolean;
            passed_special_char: boolean;
        };
    } {
        if (!password) {
            return {
                is_strong: false,
                tests: {
                    passed_min_length: false,
                    passed_upper_case: false,
                    passed_lower_case: false,
                    passed_number: false,
                    passed_special_char: false
                }
            };
        }

        const res = {
            // init the return obj
            is_strong: true,
            tests: {
                passed_min_length: false,
                passed_upper_case: false,
                passed_lower_case: false,
                passed_number: false,
                passed_special_char: false
            }
        };

        if (password.length >= 12) {
            res.tests.passed_min_length = true;
        }

        res.tests.passed_lower_case = /[a-z]/.test(password);
        res.tests.passed_number = /[0-9]/.test(password);

        res.tests.passed_upper_case = true;
        // res.tests.passed_upper_case = /[A-Z]/.test(password); // not used for now due to browser vendors suggested password
        res.tests.passed_special_char = true;
        // const specialCharacters: Array<string> = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', '{', '}', '[', ']', ':', ';', '<', '>', ',', '.', '?', '~', '\\', '/'];
        // res.tests.passed_special_char = specialCharacters.some(char => password.includes(char)); // not used for now due to browser vendors suggested password

        for (const key in res.tests) {
            if (!res.tests[key]) res.is_strong = false;
        }

        return res;
    }

    validatePhoneNumber(phoneNumber: string, region?): PhoneNumberUtil.ValidationResult {
        const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
        const phoneNumberObj = phoneUtil.parse(phoneNumber, region ?? 'EU');
        return phoneUtil.isPossibleNumberWithReason(phoneNumberObj);
    }

    // export interface DescriptionPart {
    //     type: 'translate' | 'fixed' | 'date';
    //     value: string | number;
    // }
    parseTranslationArray(desriptionString: string): string {
        const desriptionParts: DescriptionPart[] = JSON.parse(desriptionString);
        let result = '';
        for (const part of desriptionParts) {
            switch (part.type) {
                case 'fixed':
                    // fixed is a string | number value, should be displayed at it is
                    if (part.currency) {
                        result += this.localizeNumberWithCurrency(parseInt(part.value) / 100, 2, 2, part.currency);
                    } else {
                        result += String(part.value);
                    }
                    break;
                case 'translate':
                    // translate is a string key for localize translation - example: 'coupon.custom_terms.every'
                    result += this.translate.instant(part.value as string);
                    break;
                case 'date':
                    // date is a unix timestamp
                    result += this.getLocalizedDate(parseInt(part.value));
                    break;

                default:
                    break;
            }
            if (!part.noSpace) {
                result += ' ';
            }
        }
        return result.trim();
    }

    roundToTwoDecimals(num: number): number {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }
}

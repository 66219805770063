import { Injectable } from '@angular/core';
import { AntonHealthTableSettings } from './local-storage.models';
import { Language } from '@constants/supportedLanguages';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor() { }

    clearOnSignOut() {
        this.removePasswordSet();

    }

    setCallbackUrl(url: string): void {
        console.log('setting callback url', url);
        localStorage.setItem('callbackUrl', url);
    }

    getCallbackUrl(): string | null {
        return localStorage.getItem('callbackUrl');
    }

    removeCallbackUrl(): void {
        localStorage.removeItem('callbackUrl');
    }

    setPasswordSet(): void {
        localStorage.setItem('passwordSet', 'true');
    }

    getPasswordSet(): string | null {
        return localStorage.getItem('passwordSet');
    }

    removePasswordSet(): void {
        localStorage.removeItem('passwordSet');
    }

    setLanguage(languageObject: Language): void {
        localStorage.setItem('language', JSON.stringify(languageObject));
    }

    getLanguage(): Language | null {
        return JSON.parse(localStorage.getItem('language'));
    }

    removeLanguage(): void {
        localStorage.removeItem('language');
    }

    setAntonHealthTableSettings(settings: AntonHealthTableSettings): void {
        localStorage.setItem('antonHealthTableSettings', JSON.stringify(settings));
    }

    getAntonHealthTableSettings(): AntonHealthTableSettings | null {
        return JSON.parse(localStorage.getItem('antonHealthTableSettings'));
    }

}
